// 开发环境域名
// https://php-b2csaas.yixiangonline.com
// const host_development = 'http://1270.com'
const host_development = 'https://cy1270.440.red'

export default {
  // 版本
  version: '3.2.0',
  baseURL: process.env.NODE_ENV == 'production' ? '' : host_development,
  tencentMapKey: 'FWEBZ-WHSHV-IRFPO-UNMRL-5EUWV-BFBFW'
}
