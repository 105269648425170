import Main from '@/layout/main.vue'
const routes = [
  {
    path: '/user',
    name: 'user',
    meta: { title: '用户' },
    redirect: '/user/list',
    component: Main,
    children: [
      {
        path: '/user/list',
        name: 'user_list',
        meta: {
          title: '用户列表',
          parentPath: '/user',
          icon: 'icon_yingyongcenter',
          permission: ['view']
        },
        component: () => import('@/views/user/user.vue')
      },
      {
        path: '/user/user_detail',
        name: 'user_detail',
        meta: {
          hidden: true,
          title: '用户详情',
          parentPath: '/user',
          prevPath: '/user/list',
          permission: ['view']
        },
        component: () => import('@/views/user/user_details.vue')
      }
    ]
  }
]

export default routes
