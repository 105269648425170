import Main from '@/layout/main.vue'
import Blank from '@/layout/blank.vue'

const routes = [{
    path: '/shop',
    name: 'shop',
    meta: {title: '门店'},
    redirect: '/shop/lists',
    component: Main,
    children: [
        {
            path: '/shop/lists',
            name: 'lists',
            meta: {
                title: '门店管理',
                parentPath: '/shop',
                icon: 'icon_ziti_store',
                permission: ['view']
            },
            component: Blank,
            redirect: '/shop/lists',
            children: [
                {
                    path: '/shop/lists',
                    name: 'shop_lists',
                    meta: {
                        title: '门店列表',
                        parentPath: '/shop',
                        permission: ['view']
                    },
                    component: () => import('@/views/shop/shop/lists.vue')
                },
                {
                    path: '/shop/lists_detail',
                    name: 'lists_detail',
                    meta: {
                        hidden: true,
                        title: '门店信息',
                        parentPath: '/shop',
                        prevPath: '/shop/lists'
                        // permission: ['view']
                    },
                    component: () => import('@/views/shop/shop/lists_edit.vue')
                },
                {
                    path: '/shop/modify_account',
                    name: 'modify_account',
                    meta: {
                        hidden: true,
                        title: '修改超级管理员账号',
                        parentPath: '/shop',
                        prevPath: '/shop/lists',
                        permission: ['view']
                    },
                    component: () => import('@/views/shop/shop/modify_account.vue')
                },
                {
                    path: '/shop/package_record',
                    name: 'package_record',
                    meta: {
                        hidden: true,
                        title: '套餐记录',
                        parentPath: '/shop',
                        prevPath: '/shop/lists',
                        permission: ['view']
                    },
                    component: () => import('@/views/shop/shop/package_record.vue')
                }
            ]
        },
        /* {
          path: '/shop/set_meal',
          name: 'set_meal',
          meta: {
            title: '套餐管理',
            parentPath: '/shop',
            icon: 'icon_order_guanli',
            permission: ['view']
          },
          component: Blank,
          redirect: '/shop/set_meal',
          children: [
            {
              path: '/shop/set_meal',
              name: 'set_meal',
              meta: {
                title: '套餐列表',
                parentPath: '/shop',
                permission: ['view']
              },
              component: () => import('@/views/shop/set_meal/lists.vue')
            },
            {
              path: '/shop/set_meal_edit',
              name: 'set_meal_edit',
              meta: {
                hidden: true,
                title: '商城套餐',
                parentPath: '/shop',
                prevPath: '/shop/set_meal',
                permission: ['view']
              },
              component: () => import('@/views/shop/set_meal/edit.vue')
            }
          ]
        } */
    ]
}]

export default routes
