import Main from '@/layout/main.vue'
const routes = [
  {
    path: '/agent',
    name: 'agent',
    meta: { title: '代理' },
    redirect: '/agent/list',
    component: Main,
    children: [
      {
        path: '/agent/list',
        name: 'agent_list',
        meta: {
          title: '代理列表',
          parentPath: '/agent',
          icon: 'icon_yingyongcenter',
          permission: ['view']
        },
        component: () => import('@/views/agent/agent.vue')
      },
      {
        path: '/agent/agent_detail',
        name: 'agentr_detail',
        meta: {
          hidden: true,
          title: '代理详情',
          parentPath: '/agent',
          prevPath: '/agent/list'
        },
        component: () => import('@/views/agent/detail.vue')
      },
      {
        path: '/agent/agent_add',
        name: 'agentr_add',
        meta: {
          hidden: true,
          title: '添加代理',
          parentPath: '/agent',
          prevPath: '/agent/list'
        },
        component: () => import('@/views/agent/add.vue')
      },
      {
        path: '/agent/setup_detail',
        name: 'setup_detail',
        meta: {
          title: '申请记录',
          parentPath: '/agent',
          icon: 'icon_yingyongcenter',
          permission: ['view']
        },
        component: () => import('@/views/agent/setup.vue')
      },
      {
        path: '/agent/agent_set',
        name: 'agent_set',
        meta: {
          title: '代理设置',
          parentPath: '/agent',
          icon: 'icon_yingyongcenter',
          permission: ['view']
        },
        component: () => import('@/views/agent/set.vue')
      }

    ]
  }
]

export default routes
